import {Box, Typography} from "@mui/material";

function Footer() {
    const year = (new Date()).getFullYear();
    return (
        <Box sx={{width: "100%"}}>
          <Typography variant="body1" sx={{textAlign: "center", fontSize:"0.8rem", padding:"16px"}}>
            Copyright © {year} Asian Community Transformation Trust - All Rights Reserved
          </Typography>
        </Box>
    )
}

export default Footer;