import {Avatar, Box, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import React from "react";

function About() {
    const theme = useTheme();


    // const ContentBox = styled(Box)(
    //     {
    //         padding: theme.spacing(2),
    //         width: "400px",
    //         margin:0,
    //         display:"inline-block"
    //     }
    // )

    const BoardMember = (props: {src?: string, description: string}) => (
        <Box sx={{display: 'flex', marginBottom: {xs:'2.5rem', sm:'1rem'}, alignItems: 'center', gap: '1rem', flexDirection:{xs:'column', sm:'row'}}}>
            {/*<Avatar sx={{height: '6rem', width: '6rem'}} src={props.src}/>*/}
            <Typography variant="body1" sx={{textAlign: {xs: 'center', sm: 'left'}}}>{props.description}</Typography>
        </Box>
    )

    return (
        <div id="mainContent">
            <Box sx={{padding:theme.spacing(6), backgroundColor:theme.palette.secondary.light}}>
                <Box sx={{width:{xs:"100%",md:"700px"}, margin:"auto"}}>
                    <Typography variant="h6">OUR TEAM</Typography>
                    <img src={require("../images/teamPhotoCropped.jpg")} alt="" width="100%" height="auto" style={{maxWidth: '600px', borderRadius: '15px'}}/>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        ACTT has six members on the board and a growing number of volunteers and participants.
                    </Typography>
                    <Typography variant="subtitle1"><strong>Our Board Members</strong></Typography>
                    <BoardMember
                        description="Donald Scott is a retired Christian pastor who grew up as a local shepherd in the North Island. His experience with charitable trusts and the knowledge of NZ’s bicultural journey enable him to be an effective chairman of the board."
                    />
                    <BoardMember
                        description="Wendy Lu immigrated from Taiwan almost 30 years ago and currently owns an accountancy firm in Christchurch. "
                    />
                    <BoardMember
                        description="Craig Lynch is a recent immigrant from England with a background as a nurse lecturer in the National Health Service; Craig is currently working as a nurse consultant. "
                    />
                    <BoardMember
                        description="Dr. Jason Chang is a local psychiatrist who immigrated to New Zealand with his family when he was 12 years old. "
                    />
                    <BoardMember
                        description="Josiah Jennings grew up in Rangiora and met his Chinese wife whilst running counselling workshops overseas; Josiah has a political science postgraduate degree with interests in Chinese and Asian communities, along with counselling diplomas."
                    />
                </Box>
            </Box>

            <Box sx={{padding:theme.spacing(6)}}>
                <Box sx={{width:{xs:"100%",md:"700px"}, margin:"auto"}}>
                    <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: {xs:'left', sm:'center'}}}>
                        <Avatar sx={{height: '18rem', width: '18rem'}} src={require("../images/AllenCropped.jpg")}/>
                        {/*<img src={require("../images/Allen.jpg")} alt="" width="auto" height="200px"/>*/}
                        <Typography variant="body2" sx={{margin: {xs:"1.2rem 0", sm:"1.2rem auto"}, width: "fit-content"}}>
                            Allen Hou – Trust Manager
                        </Typography>
                    </Box>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        Allen migrated to Christchurch in 1998 and calls Otautahi his home. Allen has an extensive experience around immigrant culture and community activation, and is passionate about leadership and organisation development. Since completing his PGCert in Fire Engineering (University of Canterbury) in 2010, Allen has been a youth worker (24-7 Youth Work, 2009-2012), a youth pastor (Christchurch Chinese Church, 2010-2015), a pastor (Christchurch Chinese Church, 2015-2023), a CliftonStrengths© Coach (since 2022), and our Trust manager (since 2022).
                    </Typography>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        Over the last decade, Allen has built one of the first English-speaking youth ministries among immigrant churches in Aotearoa (average 90 youths each week); this continued into starting one of the first English services in 2015. During these years, Allen had formed strong relationship and trust with the local council board and led many community-focused programmes targeting engagement and activation of the Chinese community. These days Allen work as a Strengths Coach doing professional developments and leadership coaching for private and non-profit sectors. Allen has been employed by the Trust to provide strategic insight and planning to build and develop the Trust.
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}

export default About;