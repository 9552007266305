import React from 'react';
import '../App.css';
import {
  AppBar,
  Box,
  Button, Link, Menu, MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import {Menu as MenuIcon} from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';

function Navbar() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="static" sx={{overflow: "hidden"}}>
        <Toolbar sx={{background: theme.palette.primary.light, color: theme.palette.primary.dark, padding: theme => {
            return {
              xs:`${theme.spacing(2)} ${theme.spacing(0)}`,
              md:`${theme.spacing(2)} ${theme.spacing(10)}`
            }
          }}}>
          <Box sx={{display: {xs:'block', md:'none'}}}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
              <MenuIcon/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/" underline="none">Home</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/about" underline="none">About</Link>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{width: {xs: '90%', md: '40ch'}, minWidth:'34ch'}}>
            <a href="/" style={{textDecoration: "none", color: "inherit"}}>
              <Typography variant="h5" component="div" style={{textAlign: 'left', fontFamily: "'Inter', sans-serif", fontSize: "1.4rem"}}>
                <div>ASIAN COMMUNITY</div> <div>TRANSFORMATION TRUST</div>
              </Typography>
            </a>
          </Box>
          <Box sx={{
            flexGrow: 1,
            display: {xs:'none', md:'flex'},
            justifyContent: {md:'flex-end', lg:'center'},
            gap: '1rem'
          }}>
            <Button sx={{color: theme.palette.primary.dark, fontFamily:"'Quicksand', sans-serif", fontSize: '1.1rem'}} href="/">Home</Button>
            <Button sx={{color: theme.palette.primary.dark, fontFamily:"'Quicksand', sans-serif", fontSize: '1.1rem'}} href="/about">About</Button>
            {/*<NavbarMenu parent="EVENTS" children={[{text: "Workshops", link: "/events/workshops"}, {text: "Coaching", link: "/events/coaching"}]} hasArrow={true}/>*/}
            {/*<NavbarMenu parent="SERVICES" children={[{text: "WORKSHOPS", link: "/events/workshops"}, {text: "COACHING", link: "/events/coaching"}]} hasArrow={true}/>*/}
            {/*<NavbarMenu parent="MORE" children={[{text: "WORKSHOPS", link: "/events/workshops"}, {text: "COACHING", link: "/events/coaching"}]} hasArrow={true}/>*/}
          </Box>
          <Box sx={{width: {xs: '90%', md: '40ch'}, textAlign: 'right', display: {xs:'none', lg:'block'}}}>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
