import {Box, Typography} from "@mui/material";
import {useTheme, styled} from "@mui/material/styles";
import {
    Email,
    Facebook
} from "@mui/icons-material";
import React from "react";
import ImageCarousel from "../components/ImageCarousel";

function Home() {
    const theme = useTheme();


    const ContentBox = styled(Box)(
        {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
                width: "400px",
            },
            margin:0,
            display:"inline-block"
        }
    )

    return (
        <div id="mainContent">
            <ImageCarousel/>
            <Box sx={{padding:theme.spacing(6)}}>
                <Box sx={{width:{xs:"100%",md:"700px"}, margin:"auto"}}>
                    <Typography variant="h6">OUR STORY</Typography>
                    <Typography variant="subtitle1"><strong>Who are we?</strong></Typography>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        The challenges of immigration have been experienced among immigrants across the world and among many of us as immigrants into Aotearoa New Zealand. When two young people took their own lives in a local Chinese church, then followed by COVID-19 that put a pause on the world and our nation – the silent cry of the Asians in Aotearoa was louder than ever.
                    </Typography>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        The depth and complexity of the challenges is like a slow-cooked chicken broth: isolation from supportive community, loneliness from being separated from families back home, lost in the difference in cultures and languages in new environment, confused as to how to raise up their ‘3rd culture children’, fearful with the stereotype about “Rich Asians” “taking all our jobs”; all this with some unhealthy sprinkles of lack of knowledge and information about mental and social struggles and where to seek effective help… AND MORE!
                    </Typography>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        In May 2020, we decided to peel back a few layers and deal with what we can. We operated under Te Raranga Support Trust and partnered with HeartSpace to deliver mental health coaching courses to over 100 students. In September 2021, we registered as a charitable trust and employed a manager from June 2022 to develop and grow the influence and activities of the Trust.
                    </Typography>
                    <Typography variant="body2" style={{margin:"1.2rem auto", fontSize: "1rem", fontStyle: "italic"}}>
                        We want to see<br/>
                        Asian immigrants welcomed into Aotearoa as her people,<br/>
                        And to equip them to become contributing leaders,<br/>
                        Who will meet needs in their local communities.<br/>
                    </Typography>
                    <Typography variant="body1" style={{margin:"1.2rem auto"}}>
                        Today, we run group supervision for mental health coaches; cultural engagement workshops for organisations providing services to Asian immigrants; family forum to educate and inform the Asian community; leadership development for volunteers and leaders or immigrant communities; translation and mental health support for the community; and tertiary level research within the scope of Asian NZ communities.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{padding:theme.spacing(6), backgroundColor:theme.palette.secondary.light}}>
                <Box sx={{width:"100%", margin:"auto", maxWidth:{xs:"100%", md:"1000px"}}}>
                    <Typography variant="h6">OUR FOCUS</Typography>
                    <Box sx={{display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'}}>
                        <ContentBox>
                            <Typography variant="subtitle1"><strong>Mental Health</strong></Typography>
                            <Typography variant="body2">
                                We aim to break stigma and shame around Mental Wellbeing that is present in Asian cultures so that more will accept their situation and access services by: providing education that promotes better awareness of personal mental wellbeing; supporting immigrants to serve the mental wellbeing needs in their own communities; enabling more Asian mental health professionals/service providers through advocacy, education, and mobilisation, and; bridging the link between current services and the various local Asian communities.
                            </Typography>
                        </ContentBox>
                        <ContentBox>
                            <Typography variant="subtitle1"><strong>Research and Education</strong></Typography>
                            <Typography variant="body2">
                                We aim to bridge the gap in understanding of immigrant/Asian cultural behaviours and the shifting society of Aotearoa New Zealand so that we can equip all service providers and policy makers in considering this growing demographic. Currently, there is very limited research in this field. This is achieved by: Supporting tertiary level research within the scope of Asian NZ communities and; Allowing such research to advise and support the ongoing proceedings of the trust.
                            </Typography>
                        </ContentBox>
                        <ContentBox>
                            <Typography variant="subtitle1"><strong>Leadership Development Training</strong></Typography>
                            <Typography variant="body2">
                                We aim to enable community leaders to effectively lead their communities by: working alongside community leaders in a supportive and mediatory manner; providing education so that leaders feel more confident and competent to meet the needs of their communities within the NZ context and; acting as a network bridge between various communities in order to promote collaboration and support.
                            </Typography>
                        </ContentBox>
                        <ContentBox>
                            <Typography variant="subtitle1"><strong>Community Engagement</strong></Typography>
                            <Typography variant="body2">
                                We aim to help create positive community spaces that promote belonging and goals that are in alignment with our mission and focus by: becoming involved and supporting developing spaces and initiatives and; promoting and encouraging organisations that seek to meet the needs of the communities through service-providing.
                            </Typography>
                        </ContentBox>
                    </Box>
                </Box>
            </Box>
            <Box sx={{padding:theme.spacing(6), overflow: "hidden"}}>
                <Box sx={{width:{xs:"100%",md:"700px"}, margin:"auto"}}>
                    <Typography variant="h6">CONTACT US</Typography>
                    <Typography variant="body1" sx={{margin:"1.2rem auto", textAlign: "center", display: 'flex', justifyContent: "center", gap: '0.5rem', flexDirection: {xs:'column', sm:'row'}, alignItems: 'center'}}>
                        <Email/> <a href="mailto:admin@actt.org.nz" style={{textDecoration:"none", color:"inherit"}}>admin@actt.org.nz</a>
                    </Typography>
                    <Typography variant="body1" sx={{margin:"1.2rem auto", textAlign: "center", display: 'flex', justifyContent: "center", gap: '0.5rem', flexDirection: {xs:'column', sm:'row'}, alignItems: 'center'}}>
                        <Facebook/> <a href="https://www.facebook.com/ACTT.NZ" style={{textDecoration:"none", color:"inherit"}}>Asian Community Transformation Trust</a>
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}

export default Home;
