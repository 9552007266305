import React from "react";
import {Box} from "@mui/material";

function Banner(props: {src:any}) {
  return (
    <img src={props.src} alt="" width="100%" height="auto"/>
  )
}

function ImageCarousel() {
  return (
    <Box sx={{display: 'flex', width: '100%', overflow: 'hidden'}}>
      <Banner src={require("../images/bannerCropped.jpg")}/>
    </Box>
  )
}

export default ImageCarousel;