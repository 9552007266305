import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Home from "./pages/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Footer from "./components/Footer";
import About from "./pages/About";
import { inject } from '@vercel/analytics';

// For Vercel analytics
inject();

const theme = createTheme({
    palette: {
        primary: {
            light: "white",
            main: "#367da3",
            dark: "#565656"
        },
        secondary: {
            light: "#f5fafe",
            main: "#367da3",
            dark: "#565656"
        }
    },
    typography: {
        h6: {
            marginBottom: "1.1rem",
            fontFamily: "'Inter', sans-serif",
            letterSpacing: "0.1rem"
        },
        subtitle1: {
            fontFamily: "Quicksand, sans-serif",
            marginBottom: "1rem",
        },
        body1: {
            textAlign: "left",
            fontFamily: "Raleway, sans-serif",
            fontSize: "1.1rem"
        },
        body2: {
            fontFamily: "Raleway, sans-serif",
            fontSize: "0.9rem"
        }
    }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar/>
        <BrowserRouter>
          <div>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>}/>
            </Routes>
          </div>
        </BrowserRouter>
          <Footer/>
      </ThemeProvider>
    </div>
  );
}

export default App;
